import React from 'react'
import SubmitTextBox from '../components/SubmitTextBox.jsx';
import Proposal from '../components/Proposal.jsx';
import OnboardForm from '../components/OnboardForm.jsx';

export default function Onboard() {
  return (
    <div>
        <section>
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">    
                <h1 className="text-3xl font-bold text-gray-800 mb-2">Welcome to Bounties!</h1>
                <p className="text-xl text-gray-800 ">Fill out the following information for the best experience:</p>
                <OnboardForm />
            </div>
        </section>
    </div>
  )
}