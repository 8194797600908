import '../index.css';
import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Login() {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error.message);
        return;
      }

      setSession(session);
      if (session?.user) {
        await handleUserSession(session.user);
      }
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session);
      if (session?.user) {
        await handleUserSession(session.user);
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  async function handleUserSession(user) {
    try {
      const { data: { user: currentUser }, error: userError } = await supabase.auth.getUser();
      
      if (userError) throw userError;

      const { id, email } = currentUser;
      const metadata = currentUser.user_metadata;

      const { data: existingUser, error: fetchError } = await supabase
        .from('public_users')
        .select('id')
        .eq('id', id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      const { error: upsertError } = await supabase
        .from('public_users')
        .upsert([
          {
            id,
            display_name: metadata?.name || metadata?.full_name || 'Anonymous',
            email,
          },
        ], { onConflict: 'id' });

      if (upsertError) throw upsertError;

      if (!existingUser) {
        console.log('New user added to public_users');
        navigate('/onboard');  // Redirect new users to onboard
      } else {
        console.log('Existing user updated in public_users');
        navigate('/ideas');  // Redirect returning users to ideas
      }
    } catch (error) {
      console.error('Error handling user session:', error.message);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
        {session ? (
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Logged in!</h1>
            <p>Welcome back!</p>
          </div>
        ) : (
          <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={['google']} />
        )}
      </div>
    </div>
  );
}