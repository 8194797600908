import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Proposal({ initialParam = "recent", searchQuery = '' }) {
  const [proposals, setProposals] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [param, setParam] = useState(initialParam);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q') || searchQuery; // Default to passed searchQuery if URL query is not available

  const paramLabels = {
    recent: 'Recent',
    popular: 'Popular',
    payout: 'Payout',
    expiring: 'Expiring Soon'
  };

  const isDiscoverPage = location.pathname === '/discover';

  // Filter out the 'Expiring Soon' option if the page is /discover
  const filteredParamLabels = isDiscoverPage 
    ? Object.fromEntries(Object.entries(paramLabels).filter(([key]) => key !== 'expiring')) 
    : paramLabels;

  useEffect(() => {
  async function fetchUserAndProposals() {
    try {
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) {
        console.error('Error fetching user:', authError.message);
      }

      // Set userUID only if user is available
      if (user) {
        setUserUID(user.id);
      }

      // Fetch proposals regardless of authentication status
      await getProposals(param, user ? user.id : null, query);
    } catch (error) {
      console.error('Error fetching user and proposals:', error.message);
    }
  }

  fetchUserAndProposals();
}, [param, query]);

  async function getProposals(param, userUID, searchQuery) {
    try {
      let query = supabase.from('proposals').select('id, title, description, created_by, votes, expiration, created_at');
  
      if (searchQuery) {
        // Split the search query into words
        const searchWords = searchQuery.toLowerCase().split(/\s+/).filter(Boolean);
        
        // Create a filter condition for each word
        const filterConditions = searchWords.map(word => 
          `or(title.ilike.%${word}%,description.ilike.%${word}%)`
        ).join(',');
  
        // Apply the filter conditions
        query = query.or(filterConditions);
      } else {
        // Apply other filters only if there's no search query
        if (location.pathname === '/discover') {
          query = query.lt('expiration', new Date().toISOString());
        } else if (location.pathname === '/ideas') {
          query = query.gt('expiration', new Date().toISOString());
        }
  
        if (param === 'completed') {
          query = query.eq('completed', true);
        } else if (param === 'recent') {
          query = query.order('created_at', { ascending: false });
        } else if (param === 'popular') {
          query = query.order('votes', { ascending: false });
        } else if (param === 'expiring') {
          query = query.order('expiration', { ascending: true });
        }
      }
  
      // Fetch proposals data
      const { data: proposalsData, error: proposalsError } = await query;
  
      if (proposalsError) throw proposalsError;
  
      let proposalsWithDetails = await Promise.all(proposalsData.map(async (proposal) => {
        const { count } = await getSubmissionCount(proposal.id);
        const author = await getAuthorDisplayName(proposal.created_by);
        const userVote = userUID ? await getUserVote(userUID, proposal.id) : 0;
        return { ...proposal, submissionCount: count, author, userVote };
      }));
  
      // If there's a search query, calculate match counts and sort
      if (searchQuery) {
        const searchWords = searchQuery.toLowerCase().split(/\s+/).filter(Boolean);
  
        proposalsWithDetails = proposalsWithDetails.map(proposal => {
          const titleLower = proposal.title.toLowerCase();
          const descriptionLower = proposal.description.toLowerCase();
          let matchCount = 0;
  
          searchWords.forEach(word => {
            const titleMatches = (titleLower.match(new RegExp(word, 'g')) || []).length;
            const descriptionMatches = (descriptionLower.match(new RegExp(word, 'g')) || []).length;
            matchCount += titleMatches + descriptionMatches;
          });
  
          return { ...proposal, matchCount };
        });
  
        // Sort proposals by match count in descending order
        proposalsWithDetails.sort((a, b) => b.matchCount - a.matchCount);
      }
  
      // Set the sorted proposals
      setProposals(proposalsWithDetails);
    } catch (error) {
      console.error('Error fetching proposals:', error.message);
    }
  }
  
  async function getSubmissionCount(proposalId) {
    try {
      const { count, error } = await supabase
        .from('submissions')
        .select('id', { count: 'exact' })
        .eq('proposal_id', proposalId);

      if (error) throw error;
      return { count };
    } catch (error) {
      console.error(`Error getting submission count for proposal ${proposalId}:`, error.message);
      return { count: 0 };
    }
  }

  async function getAuthorDisplayName(userUUID) {
    try {
      if (!userUUID) return 'Unknown';

      const { data, error } = await supabase
        .from('public_users')
        .select('display_name')
        .eq('id', userUUID)
        .single();

      if (error) throw error;

      return data?.display_name || 'Unknown';
    } catch (error) {
      console.error('Error fetching author display name:', error.message);
      return 'Unknown';
    }
  }

  async function getUserVote(userUID, proposalId) {
    try {
      const { data: userData, error } = await supabase
        .from('public_users')
        .select('proposal_votes')
        .eq('id', userUID)
        .single();
  
      if (error) throw error;
  
      const userVotes = userData?.proposal_votes || {};
      return userVotes[proposalId] || 0; // Return the vote for the proposal or 0 if no vote
    } catch (error) {
      console.error('Error fetching user vote:', error.message);
      return 0;
    }
  }

  async function handleVote(proposalId, vote) {
    if (!userUID) {
      console.log('Cannot submit vote, not signed in');
      alert('You must be logged in to vote.');
      return;
    }
  
    try {
      // Find the current proposal
      const currentProposal = proposals.find(p => p.id === proposalId);
      if (!currentProposal) {
        console.error('Proposal not found');
        return;
      }
  
      const existingVote = currentProposal.userVote || 0;
      const newVote = existingVote === vote ? 0 : vote; // Toggle vote off if clicking the same button
      const voteDifference = newVote - existingVote;
  
      // Update local state first
      setProposals(prevProposals => 
        prevProposals.map(proposal => {
          if (proposal.id === proposalId) {
            return {
              ...proposal,
              votes: proposal.votes + voteDifference,
              userVote: newVote
            };
          }
          return proposal;
        })
      );
  
      // Update backend
      const { data: userData, error: fetchVotesError } = await supabase
        .from('public_users')
        .select('proposal_votes')
        .eq('id', userUID)
        .single();
  
      if (fetchVotesError) throw fetchVotesError;
  
      const currentVotes = userData?.proposal_votes || {};
  
      // Update the user's vote in the public_users table
      const { error: updateVoteError } = await supabase
        .from('public_users')
        .upsert({
          id: userUID,
          proposal_votes: {
            ...currentVotes,
            [proposalId]: newVote
          }
        }, { returning: 'minimal' });
  
      if (updateVoteError) throw updateVoteError;
  
      // Update the proposal's vote count
      const { error: updateProposalError } = await supabase
        .from('proposals')
        .update({ votes: currentProposal.votes + voteDifference })
        .eq('id', proposalId);
      if (updateProposalError) throw updateProposalError;
  
    } catch (error) {
      console.error('Error handling vote:', error.message);
      // Revert local state if there was an error
      getProposals(userUID);
    }
  }
  
  
  return (
    <>
      <div className="relative mb-4">
    <div className="inline-flex items-center overflow-hidden rounded-md border bg-white">
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="px-4 py-2 text-sm/none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
      >
        {paramLabels[param]}
      </button>

      <button 
        className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>

    {isDropdownOpen && (
      <div
        className="absolute left-0 right-0 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg"
        role="menu"
      >
        <div className="p-2">
          {Object.entries(filteredParamLabels).map(([value, label]) => (
            <button
              key={value}
              onClick={() => {
                setParam(value);
                setIsDropdownOpen(false);
              }}
              className="block w-full text-left rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              role="menuitem"
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    )}
  </div>


      {/* <h1 className="text-3xl font-bold text-gray-800 mb-4 mt-4">Open Bounties</h1> */}

      {proposals.map((proposal) => (
        <article key={proposal.id} className="rounded-xl border-2 border-gray-100 bg-white mb-8">
          <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
            {/* Proposal content */}
            <div className="flex-1">
              <h3 className="font-medium sm:text-lg">
                <Link to={`/expanded-proposal/${proposal.id}`}>
                  {proposal.title}
                </Link>
              </h3>
              <p style={{ whiteSpace: 'pre-wrap' }} className="line-clamp-2 text-sm text-gray-700 mb-4">
                {proposal.description}
              </p>

              <div className="mt-2 sm:flex sm:items-center sm:gap-2">
                <div className="flex items-center gap-1 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>

                  <p className="text-xs">
                    {proposal.submissionCount} {proposal.submissionCount === 1 ? 'submission' : 'submissions'}
                  </p>
                </div>

                <span className="hidden sm:block" aria-hidden="true">&middot;</span>

                <p className="hidden sm:block sm:text-xs sm:text-gray-500 mb-0 pb-0">
                  Posted by <span className="font-medium">{proposal.author}</span>
                </p>
              </div>
            </div>

            {/* Upvote/Downvote component */}
            <div className="flex flex-col items-center">
              <button
                className={`transition-transform duration-200 ease-in-out ${
                  userUID 
                    ? proposal.userVote === 1 
                      ? 'text-green-500' 
                      : proposal.userVote === 0
                        ? 'text-gray-500 hover:text-green-500'
                        : 'text-gray-500 hover:text-green-500'
                    : 'text-gray-400 cursor-not-allowed'
                }`}
                aria-label="Upvote"
                onClick={() => userUID && handleVote(proposal.id, 1)}
                disabled={!userUID}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                </svg>
              </button>
              <span className="text-4xl font-bold transform scale-50">
                {proposal.votes}
              </span>
              <button
                className={`transition-transform duration-200 ease-in-out ${
                  userUID 
                    ? proposal.userVote === -1 
                      ? 'text-red-500' 
                      : proposal.userVote === 0
                        ? 'text-gray-500 hover:text-red-500'
                        : 'text-gray-500 hover:text-red-500'
                    : 'text-gray-400 cursor-not-allowed'
                }`}
                aria-label="Downvote"
                onClick={() => userUID && handleVote(proposal.id, -1)}
                disabled={!userUID}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
          </div>
        </article>
      ))}
    </>
  );
}
