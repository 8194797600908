import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function SubmitIdea() {
  const [platform, setPlatform] = useState('');
  const [accountType, setAccountType] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [userUID, setUserUID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error || !user) {
        console.error('Error fetching user or no user logged in:', error ? error.message : 'No user session found');
        navigate('/login');
      } else {
        setUserUID(user.id);
        loadUserPreferences(user.id);
      }
    }

    async function loadUserPreferences(userId) {
      const { data, error } = await supabase
        .from('public_users')
        .select('account_type, preferred_platform')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('Error fetching user preferences:', error.message);
      } else if (data) {
        setAccountType(data.account_type);
        setPlatform(data.preferred_platform);
      }
    }

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = ''; // Required for Chrome
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setUnsavedChanges(true);
  };

  async function handleSave(e) {
    e.preventDefault();
    if (!userUID) {
      alert('You must be logged in to save your information.');
      return;
    }

    try {
      const { error: upsertError } = await supabase
        .from('public_users')
        .upsert([
          {
            id: userUID,
            account_type: accountType,
            preferred_platform: platform, 
          }
        ]);

      if (upsertError) throw upsertError;

      alert('Information saved successfully!');
      setUnsavedChanges(false);
      navigate('/profile'); // Redirect to /profile after successful save
    } catch (error) {
      console.error('Error saving information:', error.message);
      alert('Error saving information. Please try again.');
    }
  }

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-1">
            <div className="rounded-lg bg-white p-8 shadow-lg lg:p-12">
              <form onSubmit={handleSave} className="space-y-4">
                
                <p>Which best describes you?</p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  <div>
                    <input
                      type="radio"
                      id="creator"
                      className="peer sr-only"
                      name="accountType"
                      value="Creator"
                      checked={accountType === 'Creator'}
                      onChange={handleInputChange(setAccountType)}
                      required
                    />
                    <label htmlFor="creator" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> Creator </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="viewer"
                      className="peer sr-only"
                      name="accountType"
                      value="Viewer"
                      checked={accountType === 'Viewer'}
                      onChange={handleInputChange(setAccountType)}
                      required
                    />
                    <label htmlFor="viewer" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> Viewer </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="business"
                      className="peer sr-only"
                      name="accountType"
                      value="Business"
                      checked={accountType === 'Business'}
                      onChange={handleInputChange(setAccountType)}
                      required
                    />
                    <label htmlFor="business" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> Business </span>
                    </label>
                  </div>
                </div>

                <p>
                  {accountType === 'Creator'
                    ? "Select the platform(s) you want to post content for:"
                    : accountType === 'Business'
                    ? "Select the platform(s) you want to target users on:"
                    : "Select the platform(s) you spend the most time on:"}
                </p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  <div>
                    <input
                      type="radio"
                      id="youtube"
                      className="peer sr-only"
                      name="platform"
                      value="YouTube"
                      checked={platform === 'YouTube'}
                      onChange={handleInputChange(setPlatform)}
                      required
                    />
                    <label htmlFor="youtube" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> YouTube </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="tiktok"
                      className="peer sr-only"
                      name="platform"
                      value="TikTok"
                      checked={platform === 'TikTok'}
                      onChange={handleInputChange(setPlatform)}
                      required
                    />
                    <label htmlFor="tiktok" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> TikTok </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="any"
                      className="peer sr-only"
                      name="platform"
                      value="Any"
                      checked={platform === 'Any'}
                      onChange={handleInputChange(setPlatform)}
                      required
                    />
                    <label htmlFor="any" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> Both </span>
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Save
                  </button>
                </div>
                {unsavedChanges && (
                  <p className="text-red-500 mt-2">Unsaved changes.</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
