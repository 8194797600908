import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SubmitTextBox() {
  const navigate = useNavigate();

  const handleInputFocus = () => {
    navigate('/submit');
  };

  return (
    <form action="" className="space-y-4  max-w-lg"> 
      <div>
        <div>
          <label htmlFor="idea" className="sr-only">
            Submit
          </label>
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="absolute inset-y-4 left-4 h-6 text-gray-600 pointer-events-none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
              />
            </svg>

            <input
              type="text"
              id="idea"
              className="w-full rounded-lg border-gray-200 p-4 pl-12 text-md shadow-sm font-semibold" 
              placeholder="Share your original video idea"
              onFocus={handleInputFocus}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
