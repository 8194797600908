import React, { useEffect, useState } from 'react';
import SubmitTextBox from '../components/SubmitTextBox.jsx';
import Proposal from '../components/Proposal.jsx';
import { Link, useParams } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function ExpandedProposal() {
  const { proposal_id } = useParams();
  const [proposal, setProposal] = useState({});
  const [userUID, setUserUID] = useState(null);
  const [submissions, setSubmissions] = useState([]);

  


  async function getProposal() {
    const { data } = await supabase.from("proposals").select().eq('id', proposal_id);
    setProposal(data[0]);
  }

  async function getAuthorDisplayName(userUID) {
    try {
      if (!userUID) {
        return 'Unknown';
      }
  
      const { data, error } = await supabase
        .from('public_users')
        .select('display_name')
        .eq('id', userUID)
        .single();
  
      if (error) {
        if (error.code === 'PGRST116') {
          console.warn('No user found for the provided UID:', userUID);
          return 'Unknown';
        }
        throw error;
      }
  
      return data?.display_name || 'Unknown';
    } catch (error) {
      console.error('Error fetching author display name:', error.message);
      return 'Unknown';
    }
  }
  
  async function getSubmissions() {
    try {
      const { data, error } = await supabase.from("submissions").select('*').eq('proposal_id', proposal_id);
      if (error) {
        throw error;
      }
      if (data.length > 0) {
        const submissionsWithAuthors = await Promise.all(data.map(async (submission) => {
          const authorDisplayName = await getAuthorDisplayName(submission.created_by);
          return { ...submission, authorDisplayName };
        }));
        setSubmissions(submissionsWithAuthors);
      } else {
        console.log("No submissions found");
      }
    } catch (error) {
      console.error('Error getting submissions:', error.message);
    }
  }

  useEffect(() => {
    getProposal();
  }, []);

  useEffect(() => {
    getSubmissions();
  }, []);

  useEffect(() => {
    async function getUserID() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (user) {
        setUserUID(user.id);
      }
    }
  }, []);

  async function handleVote(submissionId, vote) {
    if (!userUID) {
      console.log('Cannot submit vote, not signed in');
      alert('You must be logged in to vote.');
      return;
    }
  
    try {
      // Find the current submission
      const currentSubmission = submissions.find(s => s.id === submissionId);
      if (!currentSubmission) {
        console.error('Submission not found');
        return;
      }
  
      const existingVote = currentSubmission.userVote || 0;
      const newVote = existingVote === vote ? 0 : vote; // Toggle vote off if clicking the same button
      const voteDifference = newVote - existingVote;
  
      // Update local state
      setSubmissions(prevSubmissions => 
        prevSubmissions.map(submission => 
          submission.id === submissionId 
            ? { ...submission, votes: submission.votes + voteDifference, userVote: newVote } 
            : submission
        )
      );
  
      // Fetch user's current submission votes
      const { data: userData, error: fetchVotesError } = await supabase
        .from('public_users')
        .select('submission_votes')
        .eq('id', userUID)
        .single();
  
      if (fetchVotesError) throw fetchVotesError;
  
      const currentVotes = userData?.submission_votes || {};
  
      // Update the user's vote in the public_users table
      const { error: updateVoteError } = await supabase
        .from('public_users')
        .upsert({
          id: userUID,
          submission_votes: {
            ...currentVotes,
            [submissionId]: newVote
          }
        }, { returning: 'minimal' });
  
      if (updateVoteError) throw updateVoteError;
  
      // Update the submission's vote count in the database
      const { error: updateSubmissionError } = await supabase
        .from('submissions')
        .update({ votes: currentSubmission.votes + voteDifference })
        .eq('id', submissionId);
  
      if (updateSubmissionError) throw updateSubmissionError;
  
    } catch (error) {
      console.error('Error handling vote:', error.message);
      // Revert local state if there was an error
      getSubmissions();
    }
  }

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">{proposal.title}</h1>
          <p style={{ whiteSpace: 'pre-wrap' }}>{proposal.description}</p>
          <br />
          <p className="font-semibold text-lg">Constraints:</p>
          <p className="text-red-500 font-semibold">
            Must be posted on {proposal.platform === 'Any' ? 'YouTube or TikTok' : proposal.platform}.
          </p>

          <div className="mt-4">

          
            <Link 
              to={`/submit-content/${proposal.id}`} 
              
              className="inline-block rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-900 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring">
              Submit Your Content
            </Link>
          </div>
          
          <h2 className="text-3xl font-bold text-gray-800 mt-8">Submissions</h2>
        
          {submissions.length === 0 ? (
            <div className="text-center mt-16">
              <p className="text-3xl text-gray-500">Be the first to submit!</p>
              <p className="text-gray-500 sm:text-xl">Early submissions are more likely to be rewarded.</p>
            </div>
          ) : (
            submissions.map((submission) => (
<section className="mb-0 pb-0" key={submission.id}>
    <article className="rounded-xl border-2 border-gray-100 bg-white mt-4">
      <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
        <div>
          <h3 className="font-medium sm:text-lg">
            <Link to={submission.link}>
              {submission.title}
            </Link>
          </h3>
  
          <p className="line-clamp-2 mt-2 text-sm text-gray-700">
            {submission.link}
          </p>
  
          <div className="mt-2 sm:flex sm:items-center sm:gap-2">
            <p className="hidden sm:block sm:text-xs sm:text-gray-500">
              Posted by <span className="font-medium">{submission.authorDisplayName}</span>
            </p>
          </div>
        </div>

        {/* Upvote/Downvote Component */}
        <div className="flex flex-col items-center">
          <button
            className={`transition-transform duration-200 ease-in-out ${
              userUID 
                ? submission.userVote === 1 
                  ? 'text-green-500' 
                  : submission.userVote === 0
                    ? 'text-gray-500 hover:text-green-500'
                    : 'text-gray-500 hover:text-green-500'
                : 'text-gray-400 cursor-not-allowed'
            }`}
            aria-label="Upvote"
            onClick={() => userUID && handleVote(submission.id, 1, 'submission_votes')}
            disabled={!userUID}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
            </svg>
          </button>
          <span className="text-4xl font-bold transform scale-50">
            {submission.votes }
          </span>
          <button
            className={`transition-transform duration-200 ease-in-out ${
              userUID 
                ? submission.userVote === -1 
                  ? 'text-red-500' 
                  : submission.userVote === 0
                    ? 'text-gray-500 hover:text-red-500'
                    : 'text-gray-500 hover:text-red-500'
                : 'text-gray-400 cursor-not-allowed'
            }`}
            aria-label="Downvote"
            onClick={() => userUID && handleVote(submission.id, -1, 'submission_votes')}
            disabled={!userUID}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </div>
      </div>
    </article>
  </section>

            ))
          )}
        </div>
      </section>
    </div>
  );
  
}
