import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Proposal from '../components/Proposal.jsx';
import SearchBox from '../components/SearchBox.jsx';

export default function Search() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get('q') || '';

  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    // Update the query state when the URL changes
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleSearch = (newQuery) => {
    // Update the query state
    setQuery(newQuery);
    // Update the URL without reloading the page
    window.history.pushState(null, '', `/search?q=${encodeURIComponent(newQuery)}`);
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
      <SearchBox onSearch={handleSearch} initialQuery={query} />
      
      <h1 className="text-3xl font-bold text-gray-800 mb-4 mt-4">
        Bounties related to "{query}"
      </h1>
      
      {/* If empty, say, "Don't see your idea, post a new one here!" */}
      {/* Idea text box */}
      <Proposal param="search" searchQuery={query} />
    </div>
  );
}
