// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useNavigate } from 'react-router-dom';

// const Redirect = () => {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTikTokData = async () => {
//       const urlSearchParams = new URLSearchParams(window.location.search);
//       const code = urlSearchParams.get('code');
      
//       try {
//         const response = await axios.post("/server/tiktokaccesstoken", { code });
        
//         // Assuming the response includes the videos data
//         if (response.data && response.data.videos) {
//           // Store the videos in localStorage or state management solution
//           localStorage.setItem('tiktokVideos', JSON.stringify(response.data.videos));
//           navigate('/profile'); // Redirect to profile page after successful data fetch
//         } else {
//           setError("No videos data received from server");
//         }
//       } catch (error) {
//         console.error("Error fetching TikTok data:", error);
//         setError("Failed to fetch TikTok data. Please try again.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTikTokData();
//   }, [navigate]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return <div>Redirecting to profile...</div>;
// };

// export default Redirect;

import React from "react";
import { useEffect } from "react";
import axios from "axios";

const Redirect= () => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get('code');
    // http://localhost:4000/tiktokaccesstoken
    axios.post("https://bountiesapp.com/server/tiktokaccesstoken", {
    code: code,
    });
  }, []);
  
  return <div>Redirect page</div>;
};

export default Redirect;
