import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function SubmitIdea() {
  const [oneLiner, setOneLiner] = useState('');
  const [platform, setPlatform] = useState('');
  const [message, setMessage] = useState('');
  const [userUID, setUserUID] = useState(null);
  const [expiration, setExpiration] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [isDateValid, setIsDateValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error.message);
        return;
      }
      if (user) {
        setUserUID(user.id);
        console.log(user.id);
      } else {
        navigate('/login');
      }
    }
    fetchUser();
  }, [navigate]);

  const [daysUntilExpiration, setDaysUntilExpiration] = useState(1);

  const handleDateChange = (date) => {
    setExpiration(date);
    const isValid = date > new Date();
    setIsDateValid(isValid);
    if (isValid) {
      const diffInTime = date.getTime() - new Date().getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
      setDaysUntilExpiration(diffInDays);
    }
  };
  
  async function submit(e) {
    e.preventDefault();
    if (!userUID) {
      alert('You must be logged in to submit an idea.');
      return;
    }
  
    if (!isDateValid) {
      alert('Please choose a valid date before submitting.');
      return;
    }
  
    try {
      const formattedExpiration = expiration.toISOString(); // Convert expiration date to ISO string
  
      const { data, error } = await supabase
        .from('proposals')
        .insert([
          { 
            title: oneLiner, 
            description: message, 
            platform: platform, 
            created_by: userUID, 
            expiration: formattedExpiration  // Use formatted date
          }
        ]);
      if (error) throw error;
  
      alert('Idea submitted successfully!');
      // Reset form fields after successful submission
      setOneLiner('');
      setPlatform('');
      setMessage('');
      setExpiration(new Date(new Date().setDate(new Date().getDate() + 1))); // Reset to next day's date
    } catch (error) {
      console.error('Error submitting idea:', error.message);
      alert('Error submitting idea. Please try again.');
    }
  }
  

  return (
    <div>
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg">
                Submit your original video idea.
              </p>
            </div>
            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form onSubmit={submit} className="space-y-4">
                <div>
                  <label htmlFor="oneLiner" className="sr-only">One-liner describing your idea</label>
                  <input
                    type="text"
                    id="oneLiner"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="A one-liner describing your idea"
                    value={oneLiner}
                    onChange={(e) => setOneLiner(e.target.value)}
                    required
                  />
                </div>
                <p>Which platform should this content be posted on?</p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  <div>
                    <input
                      type="radio"
                      id="youtube"
                      className="peer sr-only"
                      name="platform"
                      value="YouTube"
                      checked={platform === 'YouTube'}
                      onChange={(e) => setPlatform(e.target.value)}
                      required
                    />
                    <label htmlFor="youtube" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> YouTube </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="tiktok"
                      className="peer sr-only"
                      name="platform"
                      value="TikTok"
                      checked={platform === 'TikTok'}
                      onChange={(e) => setPlatform(e.target.value)}
                      required
                    />
                    <label htmlFor="tiktok" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> TikTok </span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="any"
                      className="peer sr-only"
                      name="platform"
                      value="Any"
                      checked={platform === 'Any'}
                      onChange={(e) => setPlatform(e.target.value)}
                      required
                    />
                    <label htmlFor="any" className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white" tabIndex="0">
                      <span className="text-sm"> Any </span>
                    </label>
                  </div>
                </div>

                <div>
                <p>Give a deadline for creators to make your idea. </p>
                  <DatePicker
                    selected={expiration}
                    onChange={handleDateChange}
                    dateFormat="Pp"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm mt-2"
                  />
                  {!isDateValid ? (
                    <p className="text-red-500 text-sm font-semibold mt-2">Choose a valid date.</p>
                  ) : (
                    <p className="text-gray-400 text-sm font-semibold mt-2">Creators have {daysUntilExpiration} {daysUntilExpiration === 1 ? 'day' : 'days'} to make your idea.</p>
                  )}
                </div>
                <p>What else should creators know about your idea? </p>
                <div>
                  <label htmlFor="message" className="sr-only">Extended description about your idea</label>
                  <textarea
                    id="message"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Extended description.
Tip: Include relevant links, similar videos, etc."
                    rows="8"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Submit Idea!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}