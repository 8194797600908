import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Profile() {
  const [videos, setVideos] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [user, setUser] = useState({ account_type: '', preferred_platform: '' });
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [selectedTab, setSelectedTab] = useState('bounties');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };



  const fetchProposals = async (userUID) => {
    try {
      const { data, error } = await supabase
        .from('proposals')
        .select('*')
        .eq('created_by', userUID);

      if (error) {
        console.error('Error fetching proposals:', error.message);
      } else {
        setProposals(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching proposals:', error.message);
    }
  };

  const fetchSubmissions = async (userUID) => {
    try {
      const { data, error } = await supabase
        .from('submissions')
        .select('*')
        .eq('created_by', userUID);

      if (error) {
        console.error('Error fetching submissions:', error.message);
      } else {
        setSubmissions(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching submissions:', error.message);
    }
  };


  useEffect(() => {
    const checkUserSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error.message);
        return;
      }

      if (session) {
        const userUID = session.user.id;

        // Fetch videos
        fetchVideos(userUID);

        // Initialize Google API (assuming it's a separate function you've defined)
        initializeGoogleAPI();

        // Fetch user data
        const { data: userData, error: userError } = await supabase
          .from('public_users')
          .select('display_name, account_type, preferred_platform')
          .eq('id', userUID)
          .single();

        if (userError) {
          console.error('Error fetching user data:', userError.message);
        } else if (userData) {
          setDisplayName(userData.display_name);
          setUser({
            account_type: userData.account_type || '',
            preferred_platform: userData.preferred_platform || '',
          });
        }

        // Fetch submissions and proposals
        fetchSubmissions(userUID);
        fetchProposals(userUID);
      } 

      // else {
      //   navigate('/login');
      // }

      // Check if URL contains TikTok authorization code and state
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (code && state) {
        await handleTikTokCallback(code, state);
      }
    };

    checkUserSession();
  }, [navigate]);


  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      navigate('/ideas');
    }
  };

  const handleEdit = async () => {
    navigate('/onboard');
  }

  const initializeGoogleAPI = () => {
    window.gapi.load('client:auth2', () => {
      window.gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID })
        .then(() => {
          console.log("Google API client initialized");
        })
        .catch(err => {
          console.error("Error initializing Google API client:", err);
        });
    });
  };

  const authenticate = () => {
    return window.gapi.auth2.getAuthInstance()
      .signIn({ scope: "https://www.googleapis.com/auth/youtube.readonly" })
      .then(() => {
        console.log("Sign-in successful");
      })
      .catch(err => {
        console.error("Error signing in:", err);
      });
  };

  const loadClient = () => {
    window.gapi.client.setApiKey(process.env.REACT_APP_YOUTUBE_API_KEY);
    return window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
      .then(() => {
        console.log("GAPI client loaded for API");
      })
      .catch(err => {
        console.error("Error loading GAPI client for API:", err);
      });
  };

  const executeYouTube = async (userId) => {
    try {
      const response = await window.gapi.client.youtube.channels.list({
        "part": ["contentDetails"],
        "mine": true
      });

      const channelId = response.result.items[0].id;

      const playlistResponse = await window.gapi.client.youtube.playlists.list({
        "part": ["contentDetails"],
        "channelId": channelId,
        "mine": true
      });

      const playlistId = playlistResponse.result.items[0].id;

      const videosResponse = await window.gapi.client.youtube.playlistItems.list({
        "part": ["snippet"],
        "playlistId": playlistId
      });

      const videos = videosResponse.result.items.map(item => {
        const videoId = item.snippet.resourceId.videoId;
        return { youtube: videoId };
      });

      const { error: upsertError } = await supabase
        .from('public_users')
        .upsert({
          id: userId,
          videos: supabase.fn.arrayAppend('videos', videos)
        });

      if (upsertError) throw upsertError;

      console.log("YouTube videos upserted successfully");

      await fetchVideos();

    } catch (error) {
      console.error("Execute error", error);
    }
  };

  const fetchVideos = async () => {
    try {
      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;

      if (userId) {
        const { data, error } = await supabase
          .from('public_users')
          .select('videos')
          .eq('id', userId)
          .single();

        if (error) throw error;

        const videoUrls = data.videos.map(video => {
          if (video.youtube) {
            return `https://www.youtube.com/watch?v=${video.youtube}`;
          } else if (video.tiktok) {
            return `https://www.tiktok.com/@user/video/${video.tiktok}`;
          }
          return null;
        }).filter(url => url !== null);

        setVideos(videoUrls);
      }
    } catch (error) {
      console.error('Error fetching videos:', error.message);
    }
  };

  const handleAuthorizeAndFetchYouTube = async () => {
    try {
      await authenticate();
      await loadClient();

      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;

      if (userId) {
        await executeYouTube(userId);
      }
    } catch (error) {
      console.error("Error during YouTube authorization and fetching:", error);
    }
  };

  const request_token = async () => {
    // http://localhost:4000/oauth
    const response = await axios.get("https://bountiesapp.com/server/oauth");
    window.location.href = `${response.data.url}`;
  };

  const handleTikTokCallback = async (code, state) => {
    try {
      // Fetch the access token using the authorization code
      const response = await axios.post('https://open.tiktokapis.com/v2/oauth/token/', 
        new URLSearchParams({
          client_key: process.env.REACT_APP_TIKTOK_CLIENT_KEY,
          client_secret: process.env.REACT_APP_TIKTOK_CLIENT_SECRET,
          code: decodeURIComponent(code),
          grant_type: 'authorization_code',
          // http://localhost:3000/profile
          redirect_uri: `https://bountiesapp.com/profile`
        }), 
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      const { access_token } = response.data;

      // Fetch videos from TikTok using the access token
      const videoResponse = await axios.post('https://open.tiktokapis.com/v2/video/list/?fields=cover_image_url,id,title', {}, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        },
        data: {
          max_count: 20
        }
      });

      const { videos: tiktokVideos } = videoResponse.data;

      // Map TikTok video IDs and URLs
      const videoUrls = tiktokVideos.map(video => ({
        tiktok: video.id,
        url: `https://www.tiktok.com/@user/video/${video.id}`
      }));

      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;

      const { error: upsertError } = await supabase
        .from('public_users')
        .upsert({
          id: userId,
          videos: supabase.fn.arrayAppend('videos', videoUrls)
        });

      if (upsertError) throw upsertError;

      console.log("TikTok videos upserted successfully");

      setVideos(prevVideos => [...prevVideos, ...videoUrls.map(video => video.url)]);
      
    } catch (error) {
      console.error("Error during TikTok authorization callback:", error);
    }
  };

  return (
    
    <div>
      <section>
        
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          
          {/* {displayName} */}
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Welcome, {displayName ? displayName : "Anonymous"}!</h1>

        <div className="mt-4">
        <div className="border-b border-gray-200">
              <nav className="-mb-px flex gap-6" aria-label="Tabs">
                <a
                  href="#"
                  onClick={() => handleTabClick('bounties')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'bounties' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  My Bounties
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('submissions')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'submissions' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Submissions
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('settings')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'settings' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Settings
                </a>
              </nav>
            </div>
          </div>

          {/* Conditional Rendering based on Selected Tab */}
          {selectedTab === 'bounties' && (
            <>
              {/* <h1 className="text-2xl font-bold text-gray-800">Your Bounties</h1> */}
              {proposals.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your original ideas will appear here.</p>
              ) : (
                proposals.map((proposal) => (
                  <article key={proposal.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${proposal.id}`}>
                            {proposal.title}
                          </Link>
                        </p>
                        
                        <p className="text-sm">Number of Submissions: {proposal.submissionCount}</p>
                        <p className="text-sm">Votes: {proposal.votes}</p>
                        <p className="text-sm">You Contributed: $0</p>
                        <p className="text-sm">Total Contributed: $0</p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}

          {selectedTab === 'submissions' && (
            <>
              {submissions.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your videos will appear here.</p>
              ) : (
                submissions.map((submission) => (
                  <article key={submission.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${submission.proposal_id}`}>
                            {submission.title}
                          </Link>
                        </p>
                        <p className="text-sm">Video Link: <Link to={submission.link}>{submission.link}</Link></p>
                        <p className="text-sm">Number of Submissions: {submission.submissionCount}</p>
                        <p className="text-sm">Votes: {submission.votes}</p>
                        <p className="text-sm">Expected Payout: $0</p>
                        <p className="text-sm">Max Payout: $0</p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}

          {selectedTab === 'settings' && (
            <>
            
            <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Preferences</h1>
            <p>Display Name: {displayName ? displayName : "Anonymous"}</p>
            <p>Account Type: {user.account_type ? user.account_type : "Viewer"}</p>
            <p>Preferred Platform: {user.preferred_platform || "YouTube, TikTok"}</p>
            <button onClick={handleEdit} className="mt-2 mb-4 inline-block rounded bg-teal-600 px-4 py-2 text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring">
              Manage Preferences
            </button>


            {/* EDIT BUTTON, takes to onboard */}
            {/* see if we can track where the user is coming from on the onboard screen */}

              <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Creator Settings</h1>
              <div>
                <h2 className="text-l text-gray-800 mb-4">Link your accounts to post your videos on Bounties.</h2>
                <ul>
                  {videos.map((url, index) => (
                    <li key={index}>
                      <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleAuthorizeAndFetchYouTube}
                  className="authorize-button inline-block rounded border border-red-600 hover:bg-red-600 px-4 py-2 text-sm font-medium active:bg-transparent text-red-600 hover:text-white focus:outline-none focus:ring active:text-red-500"
                >
                  Connect YouTube Channel
                </button>
                <br />
                <button onClick={request_token} className="inline-block mt-4 rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-600 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring active:text-red-500">
                  Connect TikTok Account
                </button>
                <br />
                <button onClick={handleSignOut} className="mt-4 signout-button inline-block rounded border border-red-600 bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring active:text-red-500">
                  Sign Out
                </button>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}