import React from 'react'
import Header from '../components/Header.jsx';
import SubmitTextBox from '../components/SubmitTextBox.jsx';
import Proposal from '../components/Proposal.jsx';
import { MetaMaskProvider } from "@metamask/sdk-react";
import { useSDK } from "@metamask/sdk-react";
import { useEffect, useState } from "react";

export default function Contribute() {
  const [account, setAccount] = useState();
    const { sdk, connected, connecting, provider, chainId } = useSDK();

    const connect = async () => {
        try {
            const accounts = await sdk?.connect();
            setAccount(accounts?.[0]);
        } catch (err) {
            console.warn("failed to connect..", err);
        }
    };

    return (
        <div className="App">
            <button style={{ padding: 10, margin: 10 }} onClick={connect}>
                Connect Wallet
            </button>
            {connected && (
                <div>
                    <>
                        {chainId && "Connected chain: ${chainId}"}
                        <p></p>
                        {account && "Connected account: ${account}"}
                    </>
                </div>
            )}
        </div>
    );
};


